export default {
  filters: {
    assetType: {
      mood: "Mood / Action",
      productimages: "Produktfotos freigestellt",
      detailimages: "Detailbilder",
      videos: "Videos",
      datasheets: "Datenblätter",
    },
  },
  sort: {
    default: "Standard",
    order_by_name_asc: "Name aufsteigend",
    order_by_name_desc: "Name absteigend",
    order_by_filesize_asc: "Dateigröße aufsteigend",
    order_by_filesize_desc: "Dateigröße absteigend",
  },
  actions: {
    showAll: "Alle anzeigen",
    results: "{count} Ergebnisse",
    loadMore: "Mehr Ergebnisse laden",
  },
  messages: {
    noResults: "Keine Ergebnisse",
    noResultsFilter:
      "Keine Ergebnisse. Versuchen Sie es mit anderen Suchfiltern.",
    loading: "Lädt...",
    complete: "Alle Ergebnisse geladen",
  },
  textSearch: {
    placeholder: "Suche",
  },
  categoryDialog: {
    title: "Kategorien",
  },
  filterDialog: {
    title: "Filter",
    filters: {
      assetType: {
        label: "Medien-Typ",
      },
      sorting: {
        label: "Sortierung",
      },
    },
  },
};
