export default {
  filters: {
    assetType: {
      mood: "Mood / Action",
      productimages: "Product photos",
      detailimages: "Detail photos",
      videos: "Videos",
      datasheets: "Data sheets",
    },
  },
  sort: {
    default: "Default",
    order_by_name_asc: "Name ascending",
    order_by_name_desc: "Name descending",
    order_by_filesize_asc: "File size ascending",
    order_by_filesize_desc: "File size descending",
  },
  actions: {
    showAll: "Show all",
    results: "{count} results",
    loadMore: "Load more",
  },
  messages: {
    noResults: "No result",
    noResultsFilter: "No result. Try again with other filters.",
    loading: "Loading...",
    complete: "All results loaded",
  },
  textSearch: {
    placeholder: "Search",
  },
  categoryDialog: {
    title: "Categories",
  },
  filterDialog: {
    title: "Filter",
    filters: {
      assetType: {
        label: "Media type",
      },
      sorting: {
        label: "Sort",
      },
    },
  },
};
