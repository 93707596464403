<template>
  <auth-layout>
    <div
      class="portal__card__default bg-white px-4 py-4 container login__dialog"
    >
      <!-- Branding -->
      <auth-branding />

      <Form
        @submit="doLogin"
        class="portal__form login__form"
        :validation-schema="schema"
      >
        <!-- Email -->
        <Field name="email" v-slot="{ field, errorMessage }">
          <div class="portal__input__wrapper login__form__email">
            <input
              id="authEmail"
              type="email"
              class="portal__form__control"
              :class="{ 'is-invalid': errorMessage }"
              :placeholder="$t('modules.auth.login.fields.email')"
              v-bind="field"
            />
            <label for="authEmail" class="portal__form__label">
              {{ $t("modules.auth.login.fields.email") }}
            </label>
            <div class="invalid-feedback" v-if="errorMessage">
              {{ $t(`validations.${errorMessage}`) }}
            </div>
          </div>
        </Field>

        <!-- Password -->
        <Field name="password" v-slot="{ field, errorMessage }">
          <div class="portal__input__wrapper login__form__password">
            <input
              id="authPassword"
              type="password"
              class="portal__form__control"
              :class="{ 'is-invalid': errorMessage }"
              :placeholder="$t('modules.auth.login.fields.password')"
              v-bind="field"
            />
            <label for="authPassword" class="portal__form__label">
              {{ $t("modules.auth.login.fields.password") }}
            </label>
            <div class="invalid-feedback" v-if="errorMessage">
              {{ $t(`validations.${errorMessage}`) }}
            </div>
          </div>
        </Field>

        <!-- Passwort Reset Link -->
        <div class="login__password__link">
          <router-link :to="{ name: 'reset-password' }" class="portal__link">
            {{ $t("modules.auth.login.actions.forgotPassword") }}
          </router-link>
        </div>

        <div class="portal__buttons__group">
          <!-- Login Button -->
          <button
            class="portal__button__primary login__login__button"
            type="submit"
            :disabled="isProcessing"
          >
            {{ $t("modules.auth.login.actions.submit") }}
          </button>

          <!-- Registration Link -->
          <router-link
            :to="{ name: 'registration' }"
            class="portal__button__secondary login__registration__button"
          >
            {{ $t("modules.auth.login.actions.register") }}
          </router-link>
        </div>
      </Form>
    </div>
  </auth-layout>
</template>

<script setup>
import { ref } from "vue";
import AuthBranding from "@app/modules/auth/components/AuthBranding.vue";
import AuthLayout from "@app/layouts/auth/AuthLayout.vue";
import { login } from "@app/modules/auth/api/authApi.js";
import router from "@app/router";
import { useUserInfoStore } from "@app/common/stores/userInfoStore.js";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { useBasketStore } from "@app/common/stores/basketStore.js";

const isProcessing = ref(false);
const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const userInfoStore = useUserInfoStore();
const basketStore = useBasketStore();

const doLogin = async (values, actions) => {
  isProcessing.value = true;

  try {
    const user = await login(values.email, values.password);
    userInfoStore.login(user);
    basketStore.resetBasket();
    await router.push({ name: "dashboard" });
  } catch (e) {
    if (e.response.status === 401) {
      actions.setErrors({
        email: "invalidCredentials",
        password: "invalidCredentials",
      });
    }
  }
  isProcessing.value = false;
};
</script>
