<template>
  <portal-dialog @before-open="onBeforeOpen()" ref="dialog">
    <template v-slot:activator="{ open }">
      <button
        class="portal__button__icon asset__list__filter__trigger__button"
        :class="{ active: assetListStore.areFiltersActive }"
        @click.prevent="open()"
      >
        <span class="material-icons-outlined">
          tune
          <span
            v-if="assetListStore.areFiltersActive"
            class="position-absolute top-0 start-100 translate-middle p-1 bg-primary border border-light rounded-circle"
          />
        </span>
      </button>
    </template>
    <template v-slot="{ close }">
      <!-- Header -->
      <div class="dialog__header asset__list__filter__dialog__header">
        <!--  Icon -->
        <div class="dialog__header__icon asset__list__filter__dialog__icon">
          <span class="material-icons-outlined">tune</span>
        </div>

        <!--  Title -->
        <div
          class="portal__h5 dialog__header__title asset__list__filter__dialog__title"
        >
          {{ $t("modules.assetList.filterDialog.title") }}
        </div>

        <!-- Submit Button -->
        <div
          class="dialog__header__close asset__list__category__dialog__submit"
        >
          <button
            @click.prevent="submit()"
            class="dialog__header__close__button"
          >
            <span class="material-icons-outlined">check</span>
          </button>
        </div>

        <!-- Close Button -->
        <div class="dialog__header__close asset__list__filter__dialog__close">
          <button
            class="dialog__header__close__button"
            @click.prevent="close()"
          >
            <span class="dialog__header__close__icon material-icons-outlined">
              close
            </span>
          </button>
        </div>
      </div>

      <div
        class="portal__form dialog__body asset__list__filter__dialog__content"
      >
        <!-- Asset Type -->
        <div class="asset__list__filter__dialog__content__type">
          <div class="portal__tagline">
            {{ $t("modules.assetList.filterDialog.filters.assetType.label") }}
          </div>
          <div
            class="portal__check__wrapper"
            v-for="(option, index) in assetTypeOptions"
            :key="option"
          >
            <input
              type="checkbox"
              class="portal__form__checkbox"
              :value="option"
              :id="`assetType_${index}`"
              v-model="assetListStore.workingFilters.type"
            />
            <label
              :for="`assetType_${index}`"
              class="portal__form__checkbox__label"
            >
              {{ $t(`modules.assetList.filters.assetType.${option}`) }}
            </label>
          </div>
        </div>

        <!-- Sorting -->
        <div
          class="portal__container__spacing__default asset__list__filter__dialog__content__sorting"
        >
          <div class="portal__tagline">
            {{ $t("modules.assetList.filterDialog.filters.sorting.label") }}
          </div>
          <select
            class="portal__form__select"
            v-model="assetListStore.workingSort"
          >
            <option v-for="option in sortOptions" :key="option" :value="option">
              {{ $t(`modules.assetList.sort.${option}`) }}
            </option>
          </select>
        </div>
      </div>
    </template>
  </portal-dialog>
</template>

<script setup>
import PortalDialog from "@app/common/components/PortalDialog.vue";
import { useAssetListStore } from "@app/modules/asset-list/store/assetListStore.js";
import { ref } from "vue";

const assetTypeOptions = [
  "mood",
  "productimages",
  "detailimages",
  "videos",
  "datasheets",
];

const sortOptions = [
  "default",
  "order_by_name_asc",
  "order_by_name_desc",
  "order_by_filesize_asc",
  "order_by_filesize_desc",
];

const assetListStore = useAssetListStore();
const dialog = ref();

const submit = () => {
  assetListStore.adoptWorkingFilters();
  assetListStore.adoptWorkingSort();
  assetListStore.triggerNewSearch();
  dialog.value.close();
};

const onBeforeOpen = () => {
  assetListStore.syncWorkingFilters();
  assetListStore.syncWorkingSort();
};
</script>
